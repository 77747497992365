import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { format_ordered_number_list } from '../utilities';
import React from 'react';
import rg_logo from '../assets/images/rg-logo-black.svg';
import Table from 'react-bootstrap/Table';
import MarkdownView from 'react-showdown';

function BayDetails({ bays, pricing, backgroundCss }) {
    
    return (
        <div id="bayDetails" className={`${backgroundCss} paddsection`}>
            <Container className="py-2 paddsection">
                <h1 className="text-center">
                    <span>Our Facility</span>
                </h1>
                
                {pricing&&<PricingTable pricing={pricing}/>}

                {bays.map((bay, i) => (
                    <Card className="postcard light green" key={i}>
                        <Card.Img variant="top" src={require(`../assets/images/${bay.imageFilename}`)} />
                        <Card.Body className="">
                            <h2 className="postcard__title">{bay.title}</h2>
                            <div className="postcard__subtitle small primary-color">
                                <i className="fa fa-clock me-2 "></i>24/7
                            </div>
                            <div className="postcard__bar"></div>
                            <div className="postcard__preview-txt mb-3">{bay.description}</div>
                            <ul>
                                {bay.features && bay.features.map((feature, i) => <li key={i}>{feature}</li>)}
                            </ul>
                            <ul className="postcard__tagbox">
                                <li className="tag__item">
                                    Bays {format_ordered_number_list(bay.bayNumbers)}
                                </li>
                                {!pricing&&<li className="tag__item play green">
                                    {bay.ratePrefix && `${bay.ratePrefix} `}
                                    <i className="fa-solid fa-dollar-sign"></i>
                                    {bay.strikethroughRate && (
                                        <span className="text-decoration-line-through">{`${bay.strikethroughRate}`}</span>
                                    )}{' '}
                                    {bay.rate} + HST/ hr
                                </li>}
                            </ul>
                        </Card.Body>
                    </Card>
                ))}
                <div className="text-center mt-5">
                    Powered by
                    <img src={rg_logo} className="powered-by" alt="" />
                </div>
            </Container>
        </div>
    );
}


function PricingTable(pricingData){
    const {pricing} = pricingData;
    return(
    <Table className="pricing-table mb-5" borderless hover size="sm">
      <thead>
        <tr>
        {pricing.columns.map((col, i) => (
             <th><MarkdownView markdown={col} /></th>
        ))}
        </tr>
      </thead>
      <tbody>
        {pricing.rows.map((row, i) => (
             <tr>{row.map((cell)=><td><MarkdownView markdown={cell} /></td>)}</tr>
        ))}
      </tbody>
    </Table>
    )
}

export default BayDetails;
