import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const format_ordered_number_list = list => {
    if (list.length > 2) {
        return `${list[0]} - ${list[list.length - 1]}`;
    } else {
        return `${list[0]} & ${list[list.length - 1]}`;
    }
};

export const useContentfulRequest = query => {
    const [content, setContent] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/6oi4k7angr3f/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Authenticate the request
                    Authorization: 'Bearer zTCHGx7F2PLggPFIE5JTx6nILrc0CqYtkd_e2-IpnUs',
                },
                // send the GraphQL query
                body: JSON.stringify({ query }),
            })
            .then(response => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    setError(errors);
                }

                if (data) {
                    setIsLoaded(true);
                    setContent(data);
                }
            });
    }, [query]);

    return { error, isLoaded, content };
};

export const useLocationHash = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll to the element with the ID from the fragment identifier
        console.log(location.hash);
        if (location.hash) {
            const element = document.querySelector(location.hash);
            console.log(element);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);
};



export const ScrollToHashElement = () => {
    let location = useLocation();

    let hashElement = useMemo(() => {
        let hash = location.hash;
        const removeHashCharacter = str => {
            const result = str.slice(1);
            return result;
        };

        if (hash) {
            let element = document.getElementById(removeHashCharacter(hash));
            return element;
        } else {
            return null;
        }
    }, [location]);

    useEffect(() => {
        if (hashElement) {
            hashElement.scrollIntoView({
                behavior: 'smooth',
                // block: "end",
                inline: 'nearest',
            });
        }
    }, [hashElement]);

    return null;
};
