import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React, { useContext } from 'react';
import { PageContext } from '../app';
import { useContentfulRequest, ScrollToHashElement } from '../utilities';
function Contact(props) {
    const {
        strings: { socialData, addressData },
    } = useContext(PageContext);

    return (
        <div id="contact-us" className={`paddsection ${props.background}`}>
         <ScrollToHashElement />
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <Row className="align-items-center">
                            <Col md={3} className="p-3">
                                <i className="fa fa-map fa-2xl primary-color py-5"></i>
                                <h2>Address</h2>
                                <Address data={addressData} />
                            </Col>
                            <Col md={3} className="p-3">
                                <i className="fa fa-at fa-2xl primary-color py-5"></i>
                                <h2>Email</h2>
                                <p>
                                    <a href="mailto:info@nexusgolf.ca">info@nexusgolf.ca</a>
                                </p>
                            </Col>
                            <Col md={3} className="p-3 ">
                                <i className="fa fa-phone fa-2xl primary-color py-5"></i>
                                <h2>Call us</h2>
                                <p>
                                    <a href="tel:905-237-9868">(905) 237-9868</a>
                                </p>
                            </Col>
                            <Col md={3} className="p-3 ">
                                <h2 className="pt-5">Stay in the loop</h2>

                                <Socials data={socialData} />
                            </Col>
                        </Row>
                    </Col>
                    {/*<Col md={6} className="ps-0">
          </Col>*/}
                </Row>
            </Container>
        </div>
    );
}

function Address(props) {
    const addressData = props.data;
    return (
        <p>
            {addressData.address_line_1} {addressData.address_line_2}
            <br />
            {addressData.city}, {addressData.province_abbrev} {addressData.postal_code}
        </p>
    );
}

function Socials(props) {
    const socials = props.data;
    return (
        <ul className="social">
            {socials.map((social, i) => (
                <li className="icon" key={i}>
                    <span className="tooltip">{social.name}</span>
                    <a href={social.url}>
                        <i className={`fa-brands fa-xl fa-${social.icon}`}></i>
                    </a>
                </li>
            ))}
        </ul>
    );
}

export default Contact;
