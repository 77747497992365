import logo from '../assets/images/nexus-logo-nav.svg';
import Container from 'react-bootstrap/Container';
import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { PageContext } from '../app';
import { useContentfulRequest } from '../utilities';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
const query = `query {
  headerCollection(limit: 1){
    items{
    
    linksCollection {
      items {
            text
        url
      }
    }
    }
  }
}`;

function Header(props) {
    const { show } = props;
    const { isLoaded, content } = useContentfulRequest(query);
    const { offset } = useContext(PageContext);
    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        headerCollection: {
            items: [
                {
                    linksCollection: { items: links },
                },
            ],
        },
    } = content;

    return (
        <header id="header" className={`fixed-top bg-white ${offset > 100 || show ? 'header-scrolled' : ''}`}>
            <Navbar id="navbar" expand="xl">
                <Container>
                    <Navbar.Brand href="/">
                        <img height={30} src={logo} className="logo" alt="Nexus Golf Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="align-items-center">
                            {links.map((l, i) => (
                                <Link to={`${l.url}`} key={i} className="nav-link">
                                    {l.text}
                                </Link>
                            ))}
                            <a className="readmore-link text-uppercase" href="/book">
                                <span>Book Now</span>
                            </a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
